import React from 'react'
import Mailchimp from "../components/mailchimp"

function EmailForm({siteMetadata}) {
	return(
		<div className="email-form-wrapper">
			<div className="email-form-title">
				Stay updated, receive the latest posts from build.ms and the occasional set of curated links to help you learn and grow. Never any spam in your inbox, opt-out any time, no hard feelings.
			</div>

			<Mailchimp
				action='https://fabisevi.us17.list-manage.com/subscribe/post?u=fe32ead288fbdcd0063c8ab81&amp;id=6b4ea1b433'
				fields={
					[{
						name: 'EMAIL',
						placeholder: 'Email',
						type: 'email',
						required: true
				  	}]}
			/>
		</div>
  );
}

export default EmailForm