import React from 'react'
import EmailForm from '../components/email-form'

function CallToAction({siteMetadata}) {
	return(
		<div className="homepage-header">
			<div className="homepage-headline">Lessons about engineering, product development, design, marketing, and anything you need to build a modern business.</div>
		
			<EmailForm />
		</div>
  );
}

export default CallToAction
